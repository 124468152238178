<template>
	<!-- nav -->
	<nav class="nav nav--fit">
		<div class="nav__wrap">
			<a href="#" class="nav__btn nav__btn--prev">
				<i class="icon icon icon-left">이전 페이지</i>
			</a>
		</div>
	</nav>
	<!-- //nav -->
	<div id="container" class="container container--fit">
		<!-- [REST] 전체 내용 관리자 페이지에서 설정 -->
		<div id="sub" class="sub dormant">
			<!-- contact -->
			<section class="content">
				<div class="row">
					<div class="inner">
						<div class="inner__top">
							<h2 class="title">
								휴면 회원 해제
							</h2>
							<p class="inner__text">
								장기 미 접속으로 인해 휴면회원 전환 상태입니다.
							</p>
						</div>
						<div class="inner__wrap">
							<div class="dormant__wrap">
								<ul class="dormant__list">
									<li>
									<span class="dormant__type">
										최초 가입일
									</span>
										<span class="dormant__date">
										2021.11.01
									</span>
									</li>
									<li>
									<span class="dormant__type">
										최종 접속일
									</span>
										<span class="dormant__date">
										2021.11.02
									</span>
									</li>
									<li>
									<span class="dormant__type">
										휴면처리일
									</span>
										<span class="dormant__date red">
										2022.11.02
									</span>
									</li>
								</ul>
								<p class="description">
									정보통신망법 이용 촉진 및 정보보호 등에 관한 법률에
									의거하여, 장기간 접속하지 않으신 회원님들의 개인
									정보가
									휴면 처리 상태입니다. <br><br>
									휴면 처리 해제를 원하실 경우 아래 방법에 따라
									휴면상태를 해제하시기 바랍니다.
								</p>
								<div class="dormant__inner">
									<h3 class="dormant__title">
										인증번호
									</h3>
									<div class="dormant__auth">
										<div class="frms__item">
											<div class="frms__wrap">
												<input type="number" class="ipt-frm" :placeholder="t('10779')">
												<div class="frms__btns"><span class="frms__time"> 23:00 </span>
													<button type="button" class="btn btn-medium btn-secondary active">
														재전송
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="btns">
						<button type="button" class="btn btn-confirm">휴면 상태 해제</button>
					</div>
				</div>
			</section>
			<!-- //content -->
		</div>
	</div>
</template>
